/** @jsx jsx */
import { jsx, Styled, Flex, Grid } from 'theme-ui'
import { Container } from './item'

import ResistIcon from '../images/icons/explosion-circle-fix.svg'
import ResistIconInverse from '../images/icons/resist-icon-white.svg'

import FireIcon from '../images/icons/flame-circle-fix.svg'
import FireIconInverse from '../images/icons/fire-icon-white.svg'

import ToxicIcon from '../images/icons/not-toxic-icon.svg'
import ToxicIconInverse from '../images/icons/not-toxic-icon-white.svg'

import MeltingIcon from '../images/icons/liquid-circle-fix.svg'
import MeltingIconInverse from '../images/icons/non-melting-icon-white.svg'

import FoldedIcon from '../images/icons/blanket-circle-fix.svg'
import FoldedIconInverse from '../images/icons/foldable-icon-white.svg'

import SizeIcon from '../images/icons/ruler-circle-fix.svg'
import SizeIconInverse from '../images/icons/size-icon-white.svg'


const styles = (inverse) => (
  
  inverse 
   ?
   {
     color: 'white',
     textAlign: 'center',
   }
   :
   {
     color: 'black',
     textAlign: 'center',
   }

)

const FeatureIcons = ({props, inverse}) => (
    
    <Container 
      {...props} 
      py={13}
      sx={styles(inverse)}
    >
      <Styled.h1 as="span" sx={{
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: [3, 4, 5],
      }}>Prepare. Prevent. Protect.</Styled.h1>
      <Grid columns={[2, 3, 6]} mt={'60px'}>
        <Flex sx={{flexDirection: 'column', alignItems: 'center', flex: '1'}}>
          {inverse 
            ? <ResistIconInverse width={'125px'} />
            : <ResistIcon width={'125px'} />
          }
          <p sx={{ fontSize: 1, fontWeight: 'bold'}}>Resists instantaneous burning of 2000°F</p>
        </Flex>
        <Flex sx={{flexDirection: 'column', alignItems: 'center', flex: '1'}}>
          {inverse
            ? <FireIconInverse width={'125px'} />
            : <FireIcon width={'125px'} />
          }
          <p sx={{ fontSize: 1, fontWeight: 'bold'}}>Withstands long term heat of 600-850°F</p>
        </Flex>
        <Flex sx={{flexDirection: 'column', alignItems: 'center', flex: '1'}}>
        {inverse
          ? <ToxicIconInverse width={'125px'} />
          : <ToxicIcon width={'125px'} />
        }
          <p sx={{ fontSize: 1, fontWeight: 'bold'}}>Does not produce toxic waste gases</p>
        </Flex>
        <Flex sx={{flexDirection: 'column', alignItems: 'center', flex: '1'}}>
        {inverse
          ? <MeltingIconInverse width={'125px'} />
          : <MeltingIcon width={'125px'} />
        }
          <p sx={{ fontSize: 1, fontWeight: 'bold'}}>No burning or melting characteristics</p>
        </Flex>
        <Flex sx={{flexDirection: 'column', alignItems: 'center', flex: '1'}}>
        {inverse
          ? <FoldedIconInverse width={'125px'} />
          : <FoldedIcon width={'125px'} />
        }
          <p sx={{ fontSize: 1, fontWeight: 'bold'}}>Light weight, portable, flexible and foldable</p>
        </Flex>
        <Flex sx={{flexDirection: 'column', alignItems: 'center', flex: '1'}}>
        {inverse
          ? <SizeIconInverse width={'125px'} />
          : <SizeIcon width={'125px'} />
        }
          <p sx={{ fontSize: 1, fontWeight: 'bold'}}>Available in varying thickness and sizes</p>
        </Flex>
      </Grid>
    </Container>
)

export default FeatureIcons;
